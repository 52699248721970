import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { useContext, useEffect } from 'react';
import classNames from 'classnames';
import Link from 'gatsby-link';
import { pageTransitionStateContext } from '../components/Layout';
import { DocState, docStateContext } from '../components/resume/doc-state';
import SkillSet from '../components/resume/SkillSet';
import Panorama from '../components/resume/Panorama';
import RichLink from '../components/resume/RichLink';
import * as styles from './resume.module.scss';
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);

    this.layout = ({
      children
    }) => {
      const pageTransitionState = useContext(pageTransitionStateContext);
      useEffect(() => {
        window.openEveryDetails = () => {
          for (const details of document.querySelectorAll('details')) {
            details.setAttribute('open', true);
          }
        };
      }, []);
      return <div className={classNames(styles.resume, styles[pageTransitionState])}>
        {children}
    </div>;
    };
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <div className={styles.col2}>
        <MDXTag name="h1" components={components}>{`JongChan Choi`}</MDXTag>
        <MDXTag name="ul" components={components}>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li">{`phone: +82 10-6351-6406`}</MDXTag>
          </MDXTag>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li">{`e-mail: `}<MDXTag name="a" components={components} parentName="p" props={{
                "href": "mailto:jong@chan.moe"
              }}>{`jong@chan.moe`}</MDXTag></MDXTag>
          </MDXTag>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li">{`website: `}<Link to='/'>{`0xABCDEF.com`}</Link></MDXTag>
          </MDXTag>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li">{`github: `}<a href="https://github.com/disjukr">{`github.com/disjukr`}</a></MDXTag>
          </MDXTag>
        </MDXTag>
      </div>
      <div className={styles.col2}>
        <MDXTag name="h2" components={components}>{`TL;DR`}</MDXTag>
        <MDXTag name="p" components={components}>{`I'm quite experienced javascript programmer.`}<br />{`
loves web, graphics and programming language.`}</MDXTag>
      </div>
      <DocState initialState={{
        image: 'PANO_20140116_155642.jpg'
      }}>
        <div className={styles.col2}>
          <MDXTag name="h2" components={components}>{`Education`}</MDXTag>
          <MDXTag name="ol" components={components}>
            <MDXTag name="li" components={components} parentName="ol">
              <MDXTag name="p" components={components} parentName="li">{`Seoul Visual Media High School 2010~2013`}</MDXTag>
              {
                /*
                <docStateContext.Consumer>{([docState, setDocState]) => {
                   return <button onClick={() => setDocState({ image: 'PANO_20140116_155642.jpg' })}>see</button>;
                }}</docStateContext.Consumer>
                */
              }
            </MDXTag>
            <MDXTag name="li" components={components} parentName="ol">
              <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://blog.naver.com/sw_maestro"
                }}>{`Software Maestro 3rd`}</MDXTag>{` 2012~2013`}</MDXTag>
              {
                /*
                <docStateContext.Consumer>{([docState, setDocState]) => {
                   return <button onClick={() => setDocState({ image: 'PANO_20131220_215649.jpg' })}>see</button>;
                }}</docStateContext.Consumer>
                */
              }
            </MDXTag>
            <MDXTag name="li" components={components} parentName="ol">
              <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                  "href": "http://www.nhnnext.org/"
                }}>{`NHN NEXT 1st`}</MDXTag>{` 2013~2014`}</MDXTag>
              {
                /*
                <docStateContext.Consumer>{([docState, setDocState]) => {
                   return <button onClick={() => setDocState({ image: 'PANO_20131223_221316.jpg' })}>see</button>;
                }}</docStateContext.Consumer>
                */
              }
            </MDXTag>
          </MDXTag>
        </div>
        <div className={styles.col2}>
          {
            /*
               <docStateContext.Consumer>{([docState, setDocState]) => {
                   return <Panorama image={docState.image}/>;
               }}</docStateContext.Consumer>
            */
          }
        </div>
      </DocState>
      <div className={styles.col1}>
        <MDXTag name="h2" components={components}>{`Employment`}</MDXTag>
      </div>
      <div className={styles.col2}>
        <MDXTag name="h3" components={components}><MDXTag name="a" components={components} parentName="h3" props={{
            "href": "http://synapsoft.co.kr/"
          }}>{`Synapsoft`}</MDXTag>{` 2014-06 ~ 2016-06`}</MDXTag>
        <MDXTag name="p" components={components}>{`Involved in synap editor and naver office project.`}</MDXTag>
        <MDXTag name="p" components={components}>{`mainly implemented word document layouter`}<br />{`
and renderer from scratch.`}<br /></MDXTag>
        <MDXTag name="p" components={components}>{`maintained word document editor.`}</MDXTag>
        <SkillSet items={['ie8', 'contenteditable', 'jsp', 'flash', 'jison', 'jindojs', 'jquery', 'gulp', 'browserify', 'webpack', 'electron', 'babel', 'react', 'es2015', 'html', 'svg', 'css']} />
      </div>
      <div className={styles.col2}></div>
      <div className={styles.col2}>
        <MDXTag name="h3" components={components}><MDXTag name="a" components={components} parentName="h3" props={{
            "href": "http://www.spoqa.com/"
          }}>{`Spoqa`}</MDXTag>{` 2016-06 ~ 2018-01`}</MDXTag>
        <MDXTag name="ul" components={components}>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                "href": "https://www.dodopoint.com/"
              }}>{`dodo point mid 2016 landing page`}</MDXTag></MDXTag>
          </MDXTag>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li">{`dodo point store app v4.0 frontend`}</MDXTag>
          </MDXTag>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li">{`dodo manager frontend`}</MDXTag>
          </MDXTag>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li">{`several frontend jobs`}</MDXTag>
          </MDXTag>
        </MDXTag>
        <SkillSet items={['ie8', 'windows xp chrome', 'ios safari', 'jquery', 'jinja', 'python', 'flask', 'webpack', 'babel', 'react', 'redux', 'flow', 'typescript', 'next.js', 'redis', 'es2017', 'html', 'scss']} />
      </div>
      <div className={styles.col2}></div>
      <div className={styles.col2}>
        <MDXTag name="h3" components={components}><MDXTag name="a" components={components} parentName="h3" props={{
            "href": "https://www.devsisters.com/"
          }}>{`Devsisters`}</MDXTag>{` 2018-03 ~ current`}</MDXTag>
        <MDXTag name="ul" components={components}>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li">{`static website infrastructure`}</MDXTag>
          </MDXTag>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li">{`cookie-wars game sites frontend`}</MDXTag>
          </MDXTag>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li">{`cookie-run-ovenbreak game sites frontend`}</MDXTag>
          </MDXTag>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                "href": "https://careers.devsisters.com/"
              }}>{`careers website frontend`}</MDXTag></MDXTag>
          </MDXTag>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                "href": "https://devsisters.com/"
              }}>{`company website`}</MDXTag></MDXTag>
          </MDXTag>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                "href": "https://www.devsisters.com/"
              }}>{`company tech blog`}</MDXTag></MDXTag>
          </MDXTag>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li">{`in-house HR solution frontend`}</MDXTag>
          </MDXTag>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li">{`several in-house library (ui component lib, gatsby plugin, ...etc)`}</MDXTag>
          </MDXTag>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li">{`several website maintenance`}</MDXTag>
          </MDXTag>
        </MDXTag>
        <SkillSet items={['react', 'typescript', 'webpack', 'babel', 'cra', 'gatsby', 'aws route53', 'aws cf', 'aws s3', 'es2020', 'html', 'svg', 'scss']} />
      </div>
      <div className={styles.col2}></div>
      <div className={styles.col1}>
        <MDXTag name="h2" components={components}>{`Prize and Awards`}</MDXTag>
        <MDXTag name="h3" components={components}><MDXTag name="a" components={components} parentName="h3" props={{
            "href": "http://d2fest.kr/2013/prize-winners.html"
          }}>{`D2 Fest 2013`}</MDXTag></MDXTag>
        <MDXTag name="p" components={components}>{`2013-09-28 - encouragement prize`}</MDXTag>
        <RichLink title='NHN NEXT 최종찬 학생, D2 FEST 대학생 부문 장려상 수상' quote={`
        지난 90일동안 D2 FEST 참가자들은 개발자의 입장이 되어 끊임없는 고민과 작업 끝에
        오픈소스를 이용한 멋진 개발도구, 서비스들을 만들었습니다.
        그 중, NEXT 최종찬 학생이 만든 작품은 <croquis.js> 라는 오픈소스 라이브러리인데요.
        웹 드로잉 도구를 구현하기 쉽게 만들어주는 라이브러리입니다.
    `} image={require('../images/prize-and-awards/d22013.jpg')} href='https://blog.naver.com/nhnnext/20198423202' />
        <MDXTag name="h3" components={components}><MDXTag name="a" components={components} parentName="h3" props={{
            "href": "https://imagine.microsoft.com/en-us/Team/Index/aea4c4ac-5f45-46f5-b25d-1219775e9ab1"
          }}>{`MS Imagine Cup 2015 Korea Finals`}</MDXTag></MDXTag>
        <MDXTag name="p" components={components}>{`2015-03-13 - won at the innovation category`}</MDXTag>
        <RichLink title="MS 학생 테크놀로지 경진대회 '이매진컵' 한국대표 선발" quote={`
        한국마이크로소프트와 중앙대학교가 공동주최한 '2015 이매진컵' 한국대표 선발전이
        13일 중앙대학교 서울캠퍼스 아트센터 대극장에서 개최됐다.
        이노베이션 부문에서는 학생용 교육 소프트웨어 '상자'를 만든 '상상' 팀이
        관심사를 공유하는 사람들과 관련된 영상을 모아주는 서비스 '다뷰'를 만든 '멀티플'을 제치고 1위를 차지했다.
    `} image={require('../images/prize-and-awards/imagine-cup.jpg')} href='https://web.archive.org/web/20170305090939/https://www.msn.com/ko-kr/news/techandscience/ms-%ED%95%99%EC%83%9D-%ED%85%8C%ED%81%AC%EB%86%80%EB%A1%9C%EC%A7%80-%EA%B2%BD%EC%A7%84%EB%8C%80%ED%9A%8C-%EC%9D%B4%EB%A7%A4%EC%A7%84%EC%BB%B5-%ED%95%9C%EA%B5%AD%EB%8C%80%ED%91%9C-%EC%84%A0%EB%B0%9C/ar-AA9IuTw' />
        <MDXTag name="h3" components={components}><MDXTag name="a" components={components} parentName="h3" props={{
            "href": "http://d2campusfest.kr/2015/"
          }}>{`D2 Fest 2015`}</MDXTag></MDXTag>
        <MDXTag name="p" components={components}>{`2016-03-19 - `}<MDXTag name="a" components={components} parentName="p" props={{
            "href": "https://github.com/D2CAMPUS-FEST/2015#yaksokjs--%EC%9E%A5%EB%A0%A4%EC%83%81"
          }}>{`encouragement prize`}</MDXTag></MDXTag>
        <MDXTag name="h2" components={components}>{`Open Source`}</MDXTag>
        <MDXTag name="h3" components={components}>{`Own Project`}</MDXTag>
        <details>
          <summary>Graphics</summary>
          <MDXTag name="ul" components={components}>
            <MDXTag name="li" components={components} parentName="ul">
              <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://github.com/crosspop/Croquispop"
                }}>{`Croquispop`}</MDXTag>{` 2012 ~ 2014`}</MDXTag>
              <MDXTag name="p" components={components} parentName="li">{`  web based drawing tool using `}<MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://github.com/disjukr/croquis.js"
                }}>{`croquis.js`}</MDXTag></MDXTag>
            </MDXTag>
            <MDXTag name="li" components={components} parentName="ul">
              <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://github.com/disjukr/croquis.js"
                }}>{`croquis.js`}</MDXTag>{` 2013 ~ 2014`}</MDXTag>
              <MDXTag name="p" components={components} parentName="li">{`  javascript drawing tool library for web browser,
using `}<MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://developer.mozilla.org/en-US/docs/HTML/Canvas"
                }}>{`html5 canvas`}</MDXTag></MDXTag>
            </MDXTag>
            <MDXTag name="li" components={components} parentName="ul">
              <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://github.com/disjukr/adaptive-thresholding"
                }}>{`adaptive-thresholding`}</MDXTag>{` 2018`}</MDXTag>
              <MDXTag name="p" components={components} parentName="li">{`  adaptive thresholding implementation in rust`}</MDXTag>
            </MDXTag>
          </MDXTag>
        </details>
        <details>
          <summary>
    Language (TL;DR: see <a href="https://github.com/aheui/avis">AVIS</a> or <a href="https://0xabcdef.com/yaksok.js/#main">yaksok.js</a>)
          </summary>
          <MDXTag name="ul" components={components}>
            <MDXTag name="li" components={components} parentName="ul">
              <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://github.com/disjukr/yaksok.js"
                }}>{`yaksok.js`}</MDXTag>{` 2015 ~ 2016`}</MDXTag>
              <MDXTag name="p" components={components} parentName="li">{`  `}<MDXTag name="a" components={components} parentName="p" props={{
                  "href": "http://yaksok.org/"
                }}>{`yaksok`}</MDXTag>{` compiler library`}</MDXTag>
            </MDXTag>
            <MDXTag name="li" components={components} parentName="ul">
              <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://github.com/aheui/naheui"
                }}>{`naheui`}</MDXTag>{` 2014 ~ 2017`}</MDXTag>
              <MDXTag name="p" components={components} parentName="li">{`  the `}<MDXTag name="a" components={components} parentName="p" props={{
                  "href": "http://aheui.github.io/"
                }}>{`aheui`}</MDXTag>{` implementation for node.js`}</MDXTag>
            </MDXTag>
            <MDXTag name="li" components={components} parentName="ul">
              <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://github.com/aheui/avis"
                }}>{`avis`}</MDXTag>{` 2014 ~ 2017`}</MDXTag>
              <MDXTag name="p" components={components} parentName="li">{`  well made `}<MDXTag name="a" components={components} parentName="p" props={{
                  "href": "http://aheui.github.io/"
                }}>{`aheui`}</MDXTag>{` visual editor`}</MDXTag>
            </MDXTag>
            <MDXTag name="li" components={components} parentName="ul">
              <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://github.com/disjukr/tempath"
                }}>{`tempath`}</MDXTag>{` 2014 ~ 2015`}</MDXTag>
              <MDXTag name="p" components={components} parentName="li">{`  template language for path data, which is the superset of SVG Path Data syntax`}</MDXTag>
            </MDXTag>
            <MDXTag name="li" components={components} parentName="ul">
              <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://github.com/disjukr/makise"
                }}>{`makise`}</MDXTag>{` 2015 ~ 2016`}</MDXTag>
              <MDXTag name="p" components={components} parentName="li">{`  pretty JSON schema definition language`}</MDXTag>
            </MDXTag>
            <MDXTag name="li" components={components} parentName="ul">
              <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://github.com/disjukr/lon"
                }}>{`LON`}</MDXTag>{` 2016`}</MDXTag>
              <MDXTag name="p" components={components} parentName="li">{`  loopable notation. supports array holes, circular references, `}<MDXTag name="inlineCode" components={components} parentName="p">{`NaN`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="p">{`Infinity`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="p">{`-Infinity`}</MDXTag>{`, ...etc`}</MDXTag>
            </MDXTag>
            <MDXTag name="li" components={components} parentName="ul">
              <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://github.com/disjukr/CSON-js"
                }}>{`CSON-js`}</MDXTag>{` 2013`}</MDXTag>
              <MDXTag name="p" components={components} parentName="li">{`  an implementation of `}<MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://github.com/lifthrasiir/cson"
                }}>{`Cursive Script Object Notation`}</MDXTag></MDXTag>
            </MDXTag>
          </MDXTag>
        </details>
        <details>
          <summary>Miscellaneous</summary>
          <MDXTag name="ul" components={components}>
            <MDXTag name="li" components={components} parentName="ul">
              <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://github.com/disjukr/just-news"
                }}>{`just-news`}</MDXTag>{` 2014 ~ 2017`}</MDXTag>
              <MDXTag name="p" components={components} parentName="li">{`  web browser userscript for cleaning news site`}</MDXTag>
            </MDXTag>
            <MDXTag name="li" components={components} parentName="ul">
              <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://github.com/disjukr/psdw"
                }}>{`psdw`}</MDXTag>{` 2014`}</MDXTag>
              <MDXTag name="p" components={components} parentName="li">{`  simple javascript photoshop document(psd) writer`}</MDXTag>
            </MDXTag>
            <MDXTag name="li" components={components} parentName="ul">
              <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://github.com/disjukr/Tetris"
                }}>{`Tetris`}</MDXTag>{` 2013`}</MDXTag>
              <MDXTag name="p" components={components} parentName="li">{`  cli tetris game written in cpp`}</MDXTag>
            </MDXTag>
            <MDXTag name="li" components={components} parentName="ul">
              <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://github.com/spoqa/aeiou"
                }}>{`aeiou`}</MDXTag>{` 2017`}</MDXTag>
              <MDXTag name="p" components={components} parentName="li">{`  i18n scripts `}<MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://spoqa.github.io/2017/09/26/aeiou-tutorial.html"
                }}>{`https://spoqa.github.io/2017/09/26/aeiou-tutorial.html`}</MDXTag></MDXTag>
            </MDXTag>
          </MDXTag>
        </details>
        <MDXTag name="h3" components={components}>{`Contribution`}</MDXTag>
        <MDXTag name="h4" components={components}>{`JS Bundlers `}<MDXTag name="a" components={components} parentName="h4" props={{
            "href": "https://github.com/webpack/enhanced-resolve"
          }}>{`Webpack`}</MDXTag>{`, `}<MDXTag name="a" components={components} parentName="h4" props={{
            "href": "https://github.com/fuse-box/fuse-box"
          }}>{`FuseBox`}</MDXTag>{`, `}<MDXTag name="a" components={components} parentName="h4" props={{
            "href": "https://github.com/parcel-bundler/parcel"
          }}>{`Parcel`}</MDXTag></MDXTag>
        <MDXTag name="ul" components={components}>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li">{`( webpack ) `}<MDXTag name="a" components={components} parentName="p" props={{
                "href": "https://github.com/webpack/enhanced-resolve/pull/21"
              }}>{`normalize filenames to nfc when readdir`}</MDXTag></MDXTag>
          </MDXTag>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li">{`( fuse-box ) `}<MDXTag name="a" components={components} parentName="p" props={{
                "href": "https://github.com/fuse-box/fuse-box/pull/344"
              }}>{`normalize filename`}</MDXTag></MDXTag>
          </MDXTag>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li">{`( parcel ) `}<MDXTag name="a" components={components} parentName="p" props={{
                "href": "https://github.com/parcel-bundler/parcel/pull/322"
              }}>{`Normalize filenames to nfc for hfs+`}</MDXTag></MDXTag>
          </MDXTag>
        </MDXTag>
        <MDXTag name="h4" components={components}><MDXTag name="a" components={components} parentName="h4" props={{
            "href": "https://github.com/stephomi/sculptgl"
          }}>{`stephomi/sculptgl`}</MDXTag></MDXTag>
        <MDXTag name="ul" components={components}>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                "href": "https://github.com/stephomi/sculptgl/pull/4"
              }}>{`Add support for wacom tablet pen pressure`}</MDXTag></MDXTag>
          </MDXTag>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                "href": "https://github.com/stephomi/sculptgl/pull/13"
              }}>{`Paint on reflection material`}</MDXTag></MDXTag>
          </MDXTag>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                "href": "https://github.com/stephomi/sculptgl/pull/15"
              }}>{`Export model as ply format`}</MDXTag></MDXTag>
          </MDXTag>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                "href": "https://github.com/stephomi/sculptgl/pull/63"
              }}>{`Update korean translation`}</MDXTag></MDXTag>
          </MDXTag>
        </MDXTag>
        <MDXTag name="h4" components={components}><MDXTag name="a" components={components} parentName="h4" props={{
            "href": "https://github.com/servo/rust-cssparser"
          }}>{`servo/rust-cssparser`}</MDXTag></MDXTag>
        <MDXTag name="ul" components={components}>
          <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
              "href": "https://github.com/servo/rust-cssparser/pull/80"
            }}>{`Parse 8, 4 digits hex notations`}</MDXTag></MDXTag>
        </MDXTag>
        <MDXTag name="h4" components={components}><MDXTag name="a" components={components} parentName="h4" props={{
            "href": "https://github.com/yargs/yargs"
          }}>{`yargs/yargs`}</MDXTag></MDXTag>
        <MDXTag name="ul" components={components}>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                "href": "https://github.com/yargs/yargs/pull/296"
              }}>{`Add korean translation`}</MDXTag></MDXTag>
          </MDXTag>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                "href": "https://github.com/yargs/yargs/pull/297"
              }}>{`Fix cjk width problem on usage table`}</MDXTag></MDXTag>
          </MDXTag>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                "href": "https://github.com/yargs/yargs/issues/649"
              }}>{`More yargs translations needed`}</MDXTag></MDXTag>
          </MDXTag>
          <MDXTag name="li" components={components} parentName="ul">
            <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
                "href": "https://github.com/yargs/yargs/pull/977"
              }}>{`#976 add ko translation`}</MDXTag></MDXTag>
          </MDXTag>
        </MDXTag>
        <details>
          <summary>trivial</summary>
          <MDXTag name="ul" components={components}>
            <MDXTag name="li" components={components} parentName="ul">
              <MDXTag name="p" components={components} parentName="li">{`adambard/learnxinyminutes-docs `}<MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://github.com/adambard/learnxinyminutes-docs/pull/363"
                }}>{`[brainfuck/ko-kr][brainfuck/en] Add korean translation of brainfuck`}</MDXTag></MDXTag>
            </MDXTag>
            <MDXTag name="li" components={components} parentName="ul">
              <MDXTag name="p" components={components} parentName="li">{`facebook/graphql `}<MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://github.com/facebook/graphql/pull/14"
                }}>{`make json correct`}</MDXTag></MDXTag>
            </MDXTag>
            <MDXTag name="li" components={components} parentName="ul">
              <MDXTag name="p" components={components} parentName="li">{`whatwg/fetch `}<MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://github.com/whatwg/fetch/pull/192"
                }}>{`add a missing space`}</MDXTag></MDXTag>
            </MDXTag>
            <MDXTag name="li" components={components} parentName="ul">
              <MDXTag name="p" components={components} parentName="li">{`evanw/glslx `}<MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://github.com/evanw/glslx/pull/4"
                }}>{`fix link`}</MDXTag></MDXTag>
            </MDXTag>
            <MDXTag name="li" components={components} parentName="ul">
              <MDXTag name="p" components={components} parentName="li">{`PistonDevelopers/dyon `}<MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://github.com/PistonDevelopers/dyon/pull/246"
                }}>{`Fixed issue link`}</MDXTag></MDXTag>
            </MDXTag>
            <MDXTag name="li" components={components} parentName="ul">
              <MDXTag name="p" components={components} parentName="li">{`haxenme/nme `}<MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://github.com/haxenme/nme/pull/61"
                }}>{`Modified `}<MDXTag name="inlineCode" components={components} parentName="a">{`project.nmml`}</MDXTag>{` on stage3d sample`}</MDXTag></MDXTag>
            </MDXTag>
            <MDXTag name="li" components={components} parentName="ul">
              <MDXTag name="p" components={components} parentName="li">{`fuse-box/fuse-box `}<MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://github.com/fuse-box/fuse-box/pull/314"
                }}>{`fix doc typo`}</MDXTag></MDXTag>
            </MDXTag>
            <MDXTag name="li" components={components} parentName="ul">
              <MDXTag name="p" components={components} parentName="li">{`nodejs/node `}<MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://github.com/nodejs/node/pull/13323"
                }}>{`doc: fix typos in n-api.md`}</MDXTag></MDXTag>
              <MDXTag name="p" components={components} parentName="li">{`  `}<MDXTag name="a" components={components} parentName="p" props={{
                  "href": "https://nodejs.org/en/blog/release/v8.1.0/"
                }}>{`node v8.1.0 release note`}</MDXTag></MDXTag>
            </MDXTag>
          </MDXTag>
        </details>
      </div>

           </MDXTag>;
  }

}
export const _frontmatter = {};
    