module.exports = [{
      plugin: require('../node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/disjukr/dev/disjukr.github.com/src/components/Layout/index.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"0xABCDEF","short_name":"0xABCDEF","background_color":"#000","theme_color":"#abcdef","icon":"src/images/profile.png"},
    }]
